import React from "react";
import SEO from "../SEO";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

export default function Page({ title, description, image=null, children, schema=null,transparentNavbar=false }) {
    return (
        <>
            <SEO
                title={title}
                description={description}
                image={image}
                schema={schema}
            />
            <Navbar transparent={transparentNavbar}/>
            {children}
            <Footer dark={true} />
        </>
    );
}
